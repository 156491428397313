var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datepicker-trigger" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("airbnb-style-datepicker", {
        attrs: {
          "trigger-element-id": _vm.triggerId,
          "close-after-select": true,
          inline: _vm.inline,
          "show-shortcuts-menu-trigger": false,
          "show-action-buttons": false,
          "offset-y": 10,
          mode: "range",
          "fullscreen-mobile": _vm.fullscreenMobile,
          "disabled-dates": _vm.disabledDays,
          "customized-dates": _vm.calClasses,
          "min-date": _vm.minDate,
          "end-date": _vm.maxDate,
          "date-one": _vm.from,
          "date-two": _vm.till,
          "mobile-header": _vm.mobileHeader
        },
        on: {
          opened: _vm.onOpened,
          closed: _vm.onClosed,
          "date-one-selected": _vm.onFromSelected,
          "date-two-selected": _vm.onTillSelected
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }