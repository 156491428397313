var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "doc-box" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "voffice-popup-dialog",
        {
          ref: "dlg",
          attrs: {
            "trigger-id": _vm.triggerId,
            "header-label": _vm.subject,
            "apply-label": _vm.voMsg("close")
          }
        },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.content && !_vm.reasonText,
                  expression: "!content && !reasonText"
                }
              ],
              staticClass: "spinner"
            },
            [
              _c("div", { staticClass: "bounce1" }),
              _vm._v(" "),
              _c("div", { staticClass: "bounce2" }),
              _vm._v(" "),
              _c("div", { staticClass: "bounce3" })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.reasonText,
                  expression: "reasonText"
                }
              ],
              staticClass: "alert alert-danger",
              attrs: { role: "alert" }
            },
            [
              _vm._v(
                "\n\n            " + _vm._s(_vm.reasonText) + "\n\n        "
              )
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }